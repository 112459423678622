import React from "react"
import { Link } from "gatsby"
import {Container, Left, Title, Para, Right, Pic, Bracket, InfoCon, List,
        Goto, Wrapper, ButtonImg, BtnText, NumCon, NumImg, Number, Info, Message, Submit} from "../styles/homeStyles"
import Exam1 from "../images/exam1.png"
import Exam2 from "../images/exam2.png"
import BracketImg from "../images/brace.png"
import PhoneIcon from "../images/phone-icon.png"


const HomeInfo = () => (
    <Container>
        <Wrapper style={{width: "100%"}}>
            <InfoCon>
                <Left>
                    <Title><Bracket src={BracketImg}/>Whatever you may need</Title>
                    <Para>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt ornare massa eget egestas purus viverra accumsan in nisl. Tempor id eu nisl nunc mi ipsum faucibus. Fusce id velit ut tortor pretium. Massa ultricies mi quis hendrerit dolor magna eget. Nullam eget felis eget nunc lobortis. Faucibus ornare suspendisse sed nisi. Sagittis eu volutpat.</Para>
                </Left>
                <Right>
                    <Pic src={Exam1}/>
                </Right>
            </InfoCon>
            <InfoCon style={{flexWrap: "nowrap"}}>
                <List>
                    <Goto to="/remodel/">
                        <ButtonImg src={Exam2}/>
                        <BtnText>Remodel</BtnText>
                    </Goto>
                    <Goto to="/painting/">
                        <ButtonImg src={Exam2}/>
                        <BtnText>Painting</BtnText>
                    </Goto>
                    <Goto to="/flooring/">
                        <ButtonImg src={Exam2}/>
                        <BtnText>Flooring</BtnText>
                    </Goto>
                    <Goto to="/handyman/">
                        <ButtonImg src={Exam2}/>
                        <BtnText>Handyman</BtnText>
                    </Goto>
                    <Goto to="/general/">
                        <ButtonImg src={Exam2}/>
                        <BtnText>General</BtnText>
                    </Goto>
                </List>
            </InfoCon>
            <InfoCon>
                <Left style={{minHeight: "250px", paddingBottom: "25px"}}>
                    <Title><Bracket src={BracketImg}/>Let’s get in touch</Title>
                    <Para>Would you like to discuss a job, or have some other questions? I’m happy to talk with you and discuss whatever it is you are looking for. You can fill out the form to the right or give me a call with the number below.</Para>
                    <NumCon>
                        <NumImg src={PhoneIcon}/>
                        <Number>509 . 123 . 4567</Number>
                    </NumCon>
                </Left>
                <Right style={{minHeight: "200px"}}>
                    <Info placeholder="name" type="text" name="name"/>
                    <Info placeholder="email" type="email" name="email"/>
                    <Info placeholder="phone" type="tel" name="Phone"/>
                    <div style={{display: "flex"}}>
                        <Message placeholder="type your message here…" />
                        <Submit type="submit" value="SUBMIT" />
                    </div>
                </Right>
            </InfoCon>
        </Wrapper>
    </Container>
)

export default HomeInfo
import { Link } from "gatsby"
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: normal;
  color: #2F2B27;
  width: 100%;
`;

const Wrapper =styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
      }
`;

const Hero = styled.img`
    margin:0;
    max-height: 600px;
    width: -webkit-fill-available;
    object-fit: cover;
`;

const InfoCon = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    justify-self: flex-start;
    max-width: 45%;
    flex-wrap: wrap;
    min-height: 400px;
    margin-top: 100px;
    margin-bottom: 100px;
    @media (max-width: 768px) {
        max-width: 100%;
        margin: 25px;
        margin-top: 50px;
      }
`;

const Right = styled.div`
    display: flex;
    flex-flow: column;
    justify-self: flex-end;
    max-width: 50%;
    min-width: 50%;
    min-height: 400px;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: center;
    @media (max-width: 768px) {
        max-width: 100%;
        width: inherit;
        margin: 25px;
        min-height: 200px;
      }
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 38px;
    font-weight: bold;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
    line-height: 1;
}
`;

const Para = styled.div`
    font-size: 18px;
    width: 100%;
`;

const Pic = styled.img`
    margin:0;
    max-height: 500px;
    width: -webkit-fill-available;
    object-fit: cover;
`;

const Bracket = styled.img`
  margin:0;
  margin-right: -10px;
  min-height: 100px;
`;

const List = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @media (max-width: 768px) {
    overflow:hidden;
    flex-flow: row nowrap;
    overflow-x: scroll;
    padding: 25px;
    margin-right: 10px;
  }
`;

const Goto = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    text-transform: uppercase;
    height: 225px;
    width: 20%;
    text-decoration: none;
    color: #2F2B27;
    @media (max-width: 768px) {
        min-width: 200px;
        overflow: hidden;
        position: relative;
      }
`;

const BtnText = styled.div`
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  z-index: -1;
  @media (max-width: 768px) {
    font-size: 14px;
    position: inherit;
    z-index: 5;
    overflow: overlay;
    width: -webkit-fill-available;
    position: absolute;
    text-align: center;
  }
`;

const ButtonImg = styled.img`
margin:0;
width: -webkit-fill-available;
object-fit: cover;
:hover{
    opacity: .3;
    z-index: -2;
}
@media (max-width: 768px) {
    opacity: .3;
    z-index: -2;
  }
`;

const NumCon =styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
    @media (max-width: 768px) {
        align-self:center;
      }
`;

const NumImg = styled.img`
    margin: 0;
    height: 25px;
`;

const Number = styled.div`
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
`;

const Info = styled.input`
    padding: 10px;
    margin: 5px;
    height: 42px;
    width: -webkit-fill-available;
    border: solid #2F2B27;
    border-width: thin;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #2F2B27;
    }
    @media (max-width: 768px) {
        width: -webkit-fill-available; 
      }
`;

const Message = styled.textarea`
    padding: 10px;
    margin: 5px;
    width: 70%;
    min-height: 150px;
    border: solid #2F2B27;
    border-width: thin;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #2F2B27;
    }
    @media (max-width: 768px) {
        width: -webkit-fill-available; 
      }
`;

const Submit = styled.input`
    width: 30%;
    background-color: #2F2B27;
    color: #fff;
    padding: 10px;
    margin: 5px;
`;

export {
    Container,
    Wrapper,
    Hero, 
    Left, 
    Title, 
    Para, 
    Right, 
    Pic,
    Bracket,
    InfoCon,
    List,
    Goto,
    ButtonImg,
    BtnText,
    NumCon,
    NumImg,
    Number,
    Info,
    Message,
    Submit,
}
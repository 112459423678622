import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import {Container, Wrapper, Hero } from "../styles/homeStyles"
import HeroImg from "../images/hero.png"
import HomeInfo from "../components/homeInfo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <Wrapper>
        <Hero src={HeroImg}/>
        <HomeInfo/>
      </Wrapper>
    </Container>
  </Layout>
)

export default IndexPage
